import React from "react";
import {VictoryPie, VictoryLabel} from 'victory';

const RatioGraph = (props) => {
  const type = (props.type) ? props.type : 'subjectwise'
  const width = (props.width) ? props.width : 300;
  const halfwidth = width/2;
  const padding = (props.width) ? 15 : 15
  const innerRadius = (props.width) ? 0 : 60
  const labelRadius = (props.width) ? 10 : 60
  const fontSize = (props.width) ? 14 : 14
  const mathColor = "#EC4824"
  const hindiColor = "#2cc11e"
  const scienceColor = "#6aa6f7"
  const englishColor = "#dddb15"
  const defaultColor = "#EEEEEE"
  const class15Color = "#6aa6f7"
  const class68Color = "#dddb15"
  const Color50 = "#EC4824"
  const Color5075 = "#2cc11e"
  const Color75 = "#6aa6f7"
  let dataset = []
  let colorset = []
  if (type === 'classwise') {
    if (props.class15progress > 0) {
      dataset.push({'key': "Class 1-5", 'y': (props.isClass15NA && props.isClass15NA === 'YES') ? 0 : props.class15progress})
      colorset.push(class15Color)
    }
    if (props.class68progress > 0) {
      dataset.push({'key': "Class 6-8", 'y': (props.isClass68NA && props.isClass68NA === 'YES') ? 0 : props.class68progress})
      colorset.push(class68Color)
    }
    if (props.class15progress === 0 && props.class68progress === 0) {
      dataset.push({'key': "NA", 'y': 100})
      colorset.push(defaultColor)
      return (
        <svg width={width} height={width}>
        <VictoryPie 
            standalone={false}
            padAngle={0}
            // used to hide labels
            labelComponent={<span/>}
            innerRadius={innerRadius}
            labelRadius={labelRadius}
            padding={padding}
            width={width} height={width}
            data={[{'key': "", 'y': 100}]}
            colorScale={["#EEEEEE" ]}
            style={{ labels: { fontSize: 20, fill: "white" } }}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontSize: 25, fontWeight: 'Bold', fill: '#7B7B7B' }}
          x={halfwidth} y={halfwidth}
          text={'NA'}
        />
        </svg>
      );
    } else {
      return (
        <svg width={width} height={width}>
        <VictoryPie 
            standalone={false}
            padAngle={0}
            // used to hide labels
            innerRadius={innerRadius}
            labelRadius={labelRadius}
            labels={({ datum }) => `${datum.key} : ${datum.y}`}
            padding={padding}
            width={width} height={width}
            data={dataset}
            colorScale={colorset}
            style={{ labels: { fontSize: fontSize, fontWeight: "bold", fill: "#000000" } }}
        />
        </svg>
      );
    }
  } else if (type === 'assessment') {
    if (props.less_than_50 > 0) {
      dataset.push({'key': "<50%", 'y': (props.isClass15NA && props.isClass15NA === 'YES') ? 0 : props.less_than_50})
      colorset.push(Color50)
    }
    if (props.between_50_and_75 > 0) {
      dataset.push({'key': "50%-75%", 'y': (props.isClass13NA && props.isClass13NA === 'YES') ? 0 : props.between_50_and_75})
      colorset.push(Color5075)
    }
    if (props.greater_than_75 > 0) {
      dataset.push({'key': ">75%", 'y': (props.isClass45NA && props.isClass45NA === 'YES') ? 0 : props.greater_than_75})
      colorset.push(Color75)
    }
    if (props.less_than_50 === 0 && props.between_50_and_75 === 0 && props.greater_than_75 === 0) {
      dataset.push({'key': "NA", 'y': 100})
      colorset.push(defaultColor)
      return (
        <svg width={width} height={width}>
        <VictoryPie 
            standalone={false}
            padAngle={0}
            // used to hide labels
            labelComponent={<span/>}
            innerRadius={innerRadius}
            labelRadius={labelRadius}
            padding={padding}
            width={width} height={width}
            data={[{'key': "", 'y': 100}]}
            colorScale={["#EEEEEE" ]}
            style={{ labels: { fontSize: 20, fill: "white" } }}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontSize: 25, fontWeight: 'Bold', fill: '#7B7B7B' }}
          x={halfwidth} y={halfwidth}
          text={'NA'}
        />
        </svg>
      );
    } else {
      return (
        <svg width={width} height={width}>
        <VictoryPie 
            standalone={false}
            padAngle={0}
            // used to hide labels
            innerRadius={innerRadius}
            labelRadius={labelRadius}
            labels={({ datum }) => `${datum.key} : ${datum.y}`}
            padding={padding}
            width={width} height={width}
            data={dataset}
            colorScale={colorset}
            style={{ labels: { fontSize: fontSize, fontWeight: "bold", fill: "#000000" } }}
        />
        </svg>
      );
    }
  } else {
    if (props.mathprogress > 0) {
      dataset.push({'key': "Math", 'y': (props.isMathNA && props.isMathNA === 'YES') ? 0 : props.mathprogress})
      colorset.push(mathColor)
    }
    if (props.scienceprogress > 0) {
      dataset.push({'key': "Science", 'y': (props.isScienceNA && props.isScienceNA === 'YES') ? 0 : props.scienceprogress})
      colorset.push(scienceColor)
    }
    if (props.hindiprogress > 0) {
      dataset.push({'key': "Hindi", 'y': (props.isHindiNA && props.isHindiNA === 'YES') ? 0 : props.hindiprogress})
      colorset.push(hindiColor)
    }
    if (props.englishprogress > 0) {
      dataset.push({'key': "English", 'y': (props.isEnglishNA && props.isEnglishNA === 'YES') ? 0 : props.englishprogress})
      colorset.push(englishColor)
    }
    if (props.englishprogress === 0 && props.scienceprogress === 0 && props.hindiprogress === 0 && props.mathprogress === 0) {
      dataset.push({'key': "NA", 'y': 100})
      colorset.push(defaultColor)
      return (
        <svg width={width} height={width}>
        <VictoryPie 
            standalone={false}
            padAngle={0}
            // used to hide labels
            labelComponent={<span/>}
            innerRadius={innerRadius}
            labelRadius={labelRadius}
            padding={padding}
            width={width} height={width}
            data={[{'key': "", 'y': 100}]}
            colorScale={["#EEEEEE" ]}
            style={{ labels: { fontSize: 20, fill: "white" } }}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontSize: 25, fontWeight: 'Bold', fill: '#7B7B7B' }}
          x={halfwidth} y={halfwidth}
          text={'NA'}
        />
        </svg>
      );
    } else {
      return (
        <svg width={width} height={width}>
        <VictoryPie 
            standalone={false}
            padAngle={0}
            // used to hide labels
            innerRadius={innerRadius}
            labelRadius={labelRadius}
            labels={({ datum }) => `${datum.key} : ${datum.y}`}
            padding={padding}
            width={width} height={width}
            data={dataset}
            colorScale={colorset}
            style={{ labels: { fontSize: fontSize, fontWeight: "bold", fill: "#000000" } }}
        />
        </svg>
      );
    }
  }
}

export default RatioGraph